import { render, staticRenderFns } from "./Client.vue?vue&type=template&id=4ed41855&scoped=true"
import script from "./Client.vue?vue&type=script&lang=js"
export * from "./Client.vue?vue&type=script&lang=js"
import style0 from "@/css/styleModules.css?vue&type=style&index=0&id=4ed41855&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed41855",
  null
  
)

export default component.exports