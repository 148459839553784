import { render, staticRenderFns } from "./GlobalSettings.vue?vue&type=template&id=219fac25&scoped=true"
import script from "./GlobalSettings.vue?vue&type=script&lang=js"
export * from "./GlobalSettings.vue?vue&type=script&lang=js"
import style0 from "@/css/styleModules.css?vue&type=style&index=0&id=219fac25&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219fac25",
  null
  
)

export default component.exports