<template>
  <div class="progressive">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div class="card">
          <div class="card-body">
            <b-modal v-model="showLoadingCMD" width="auto" trap-focus :destroy-on-hide="true" aria-role="dialog"
              aria-modal :can-cancel="false">
              <template #default="props">
                <LoadingCMD @close="props.close" :CMD="cmd" :sendingCMDMessage="sendingCMDMessage"
                  :countDown="countDown" @ActionAfterLoadingProgressive="
              actionAfterLoadingProgressive()
              " :route="route">
                </LoadingCMD>
              </template>
            </b-modal>
            <div style="overflow-y: auto; height: 100% !important">
              <div class="stats">

                <div class="stats col-lg-3" v-for="global in dataModule.GlobalData.Globals" :key="global.ProgId">

                  <button class="stat" @click.stop="showProgressiveData(global)" style="min-width: 200px"
                    v-if="global.GameType == gameType.bingo">
                    <div>
                      <div class="stat-icon">
                        <span class="stat-title">{{ global.Name }}</span>
                        <!--
                                <span class="icons-stack" style="line-height: 0.75em !important; font-size: 2.5rem; top: 25px;">
                                  <i class="icons-gold icons-tabs icons-sack-money icons-stack-1x" style="font-size: 5rem;"></i>
                                  <i class="icons-white icons-xs icons icons-globe icons-stack-1x" style="top: 25px; left: 25px;"></i>
                                </span>
                                -->
                        <img src="@/img/svgs/sack-money-globe.svg" style="width: 5rem" />
                      </div>
                      <div class="stat-info">
                        <template v-if="!global.IsNone">
                          <span class="stat-title">{{
              translate("columnValue")
            }}</span>
                          {{ global.Value }}
                        </template>
                        <template v-else>
                          <span class="stat-title">&nbsp; </span>
                          &nbsp;
                        </template>
                      </div>
                    </div>
                    <footer class="card-footer" style="height: 50px">
                      <div class="col-lg-12">
                        <b-button style="
                                width: 100% !important;
                                height: 175% !important;
                                font-size: 0.8rem;
                              " icon-right="icons-1x icons-undo" icon-pack="icons"
                          :title="translate('btnResetProgressive')"
                          :disabled="!userLogged.Technician && !userLogged.Root" @click.stop="
              processResetProgressive(global, 'prog')
              " v-if="!global.IsNone && (globalTypeEnum.ServerOnly == globalType || globalTypeEnum.ClientAndServer == globalType)" />
                      </div>
                    </footer>
                  </button>
                </div>
                <div :class="'col-lg-' +
              (12 -
                3 * ((dataModule.GlobalData.Globals.length + 1) % 4))
              " />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- modal ProgData -->
    <b-modal v-model="showProgressive" width="900px" trap-focus :destroy-on-hide="true" :can-cancel="false"
      aria-role="dialog" aria-modal>
      <template>
        <div class="modal-card" style="width: 100%; height: 650px">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ this.name }}</p>
            <button type="button" class="delete" @click="closeProgressive()" />
          </header>
          <section class="modal-card-body">

            <b-tabs class="block" v-if="!this.isNone">
              <b-tab-item :label="translate('globalProgressive_Title')" icon-pack="icons"
                icon="icons-lg icons-sack-money">

                <b-table class="has-background-color-dark" :data="megapotsInfo.DataMegapot" aria-next-label="Next page"
                  aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page" striped
                  bordered>
                  <template #empty>
                    <div class="has-text-centered">
                      <h1>
                        <span class="tag is-danger">{{
              translate("lblNoDataFound")
            }}</span>
                      </h1>
                    </div>
                  </template>
                  <b-table-column field="field" :label="translate('columnField')" v-slot="props"
                    header-class="is-sticky-column-one" width="200">
                    {{ props.row.field }}
                  </b-table-column>
                  <b-table-column field="value" :label="translate('columnValue')" v-slot="props"
                    header-class="is-sticky-column-one" width="200">
                    {{ props.row.value }}
                  </b-table-column>
                </b-table>

              </b-tab-item>
              <b-tab-item label="Bingo" icon-pack="icons" icon="icons-lg icons-bingo">
                <b-tabs class="block">
                  <b-tab-item :label="translate('lblParameters')" icon-pack="icons" icon="icons-lg icons-document-table"
                    v-if="!this.isNone">
                    <b-table class="has-background-color-dark" :data="megapotsInfo.Bingo" aria-next-label="Next page"
                      aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                      striped bordered>
                      <template #empty>
                        <div class="has-text-centered">
                          <h1>
                            <span class="tag is-danger">{{
              translate("lblNoDataFound")
            }}</span>
                          </h1>
                        </div>
                      </template>
                      <b-table-column field="field" :label="translate('columnField')" v-slot="props"
                        header-class="is-sticky-column-one" width="200">
                        {{ props.row.field }}
                      </b-table-column>
                      <b-table-column field="value" :label="translate('columnValue')" v-slot="props"
                        header-class="is-sticky-column-one" width="200">
                        {{ props.row.value }}
                      </b-table-column>
                    </b-table>
                  </b-tab-item>
                  <b-tab-item v-if="false" :label="translate('engineData_tabName')" icon-pack="icons"
                    icon="icons-lg3 icons-gamepad">
                    <b-table class="has-background-color-dark" :data="progressive.dataEngine"
                      aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                      aria-current-label="Current page" striped bordered>
                      <template #empty>
                        <div class="has-text-centered">
                          <h1>
                            <span class="tag is-danger">{{
              translate("lblNoDataFound")
            }}</span>
                          </h1>
                        </div>
                      </template>
                      <b-table-column field="engines" :label="translate('lblTableEnginesDataProg')" v-slot="props"
                        header-class="is-sticky-column-one" width="200">
                        {{ props.row.field }}
                      </b-table-column>
                    </b-table>
                  </b-tab-item>
                  <b-tab-item :label="translate('fractioned_Title')" icon-pack="icons" icon="icons-lg2 icons-cubes"
                    v-if="progressive.ProgType == 'Fractioned'">
                    <div class="table-wrapper has-mobile-cards">
                      <table class="table is-bordered is-striped" style="background-color: white; color: #363636; border: 1px solid transparent;
                    border-radius: 4px; border-collapse: separate; width: 100%;">
                        <thead>
                          <tr>
                            <th
                              style="text-align: left; vertical-align: middle; border-bottom-width: 2px; border-bottom-width: 1px;"
                              scope="col" v-for="column in megapotsInfo.Bingo.columnsFrac" v-bind:value="column"
                              :key="column">
                              {{ column.field }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="line in megapotsInfo.Bingo.linesFrac" v-bind:value="line" :key="line">
                            <td style="width: 50%; text-align: left; vertical-align: middle !important;">{{ line.Bet }}
                            </td>
                            <td style="width: 50%; text-align: left; vertical-align: middle !important;"
                              v-for="Perc in line.Perc" v-bind:value="Perc" :key="Perc">
                              {{ Perc }}%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-tab-item>
                </b-tabs>
              </b-tab-item>
              <b-tab-item label="Spins" icon-pack="icons" icon="icons-lg icons-slot-machine">
                <b-tabs class="block">
                  <b-tab-item :label="translate('lblParameters')" icon-pack="icons" icon="icons-lg icons-document-table"
                    v-if="!this.isNone">
                    <b-table class="has-background-color-dark" :data="megapotsInfo.Spins" aria-next-label="Next page"
                      aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                      striped bordered>
                      <template #empty>
                        <div class="has-text-centered">
                          <h1>
                            <span class="tag is-danger">{{
              translate("lblNoDataFound")
            }}</span>
                          </h1>
                        </div>
                      </template>
                      <b-table-column field="field" :label="translate('columnField')" v-slot="props"
                        header-class="is-sticky-column-one" width="200">
                        {{ props.row.field }}
                      </b-table-column>
                      <b-table-column field="value" :label="translate('columnValue')" v-slot="props"
                        header-class="is-sticky-column-one" width="200">
                        {{ props.row.value }}
                      </b-table-column>
                    </b-table>
                  </b-tab-item>
                  <b-tab-item v-if="false" :label="translate('engineData_tabName')" icon-pack="icons"
                    icon="icons-lg3 icons-gamepad">
                    <b-table class="has-background-color-dark" :data="progressive.dataEngine"
                      aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
                      aria-current-label="Current page" striped bordered>
                      <template #empty>
                        <div class="has-text-centered">
                          <h1>
                            <span class="tag is-danger">{{
              translate("lblNoDataFound")
            }}</span>
                          </h1>
                        </div>
                      </template>
                      <b-table-column field="engines" :label="translate('lblTableEnginesDataProg')" v-slot="props"
                        header-class="is-sticky-column-one" width="200">
                        {{ props.row.field }}
                      </b-table-column>
                    </b-table>
                  </b-tab-item>
                  <b-tab-item :label="translate('fractioned_Title')" icon-pack="icons" icon="icons-lg2 icons-cubes"
                    v-if="progressive.ProgType == 'Fractioned'">
                    <div class="table-wrapper has-mobile-cards">
                      <table class="table is-bordered is-striped" style="background-color: white; color: #363636; border: 1px solid transparent;
                    border-radius: 4px; border-collapse: separate; width: 100%;">
                        <thead>
                          <tr>
                            <th
                              style="text-align: left; vertical-align: middle; border-bottom-width: 2px; border-bottom-width: 1px;"
                              scope="col" v-for="column in megapotsInfo.Spins.columnsFrac" v-bind:value="column"
                              :key="column">
                              {{ column.field }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="line in megapotsInfo.Spins.linesFrac" v-bind:value="line" :key="line">
                            <td style="width: 50%; text-align: left; vertical-align: middle !important;">{{ line.Bet }}
                            </td>
                            <td style="width: 50%; text-align: left; vertical-align: middle !important;"
                              v-for="Perc in line.Perc" v-bind:value="Perc" :key="Perc">
                              {{ Perc }}%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-tab-item>
                </b-tabs>
              </b-tab-item>
            </b-tabs>


          </section>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="showToken" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
      :can-cancel="false">
      <template #default="props">
        <Token @close="props.close" route="/modules/Progressive" :showToken="true" :showPassword="true" :showPin="false"
          @ActionAfterTokenProgressive="afterToken()" />
      </template>
    </b-modal>
    <b-modal v-model="showReset" width="600px" trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal
      :can-cancel="false">
      <template #default="props">
        <ResetValue @close="props.close" route="/modules/Progressive" :showToken="true" :showPassword="true"
          :showPin="false" @ActionAfterTokenProgressive="afterToken()" :obj="resetProgOrMulti"
          :restartCommunication="restartCommunication" />
      </template>
    </b-modal>
  </div>
</template>
<script>
import Token from "@/components/auth/Token.vue";
import ResetValue from "@/components/reset/ResetValue.vue";
import LoadingCMD from "@/components/processCMD/LoadingCMD.vue";
import { mapState } from "vuex";
import { clientCommands } from "@/js/ClientCommands.js";
import { typesModules, terminalOptions, globalType, gameType } from "@/mixins/enums";
import { countries } from "@/mixins/countryCode.js";
import "@/css/icons.css";
import en from "@/mixins/languages/en.js";
import es from "@/mixins/languages/es.js";
import nor from "@/mixins/languages/nor.js";
import swe from "@/mixins/languages/swe.js";
export default {
  name: "Progressive",
  mixins: [en, es, nor, swe],
  components: { Token, LoadingCMD, ResetValue },
  computed: mapState([
    "session",
    "communication",
    "dataModule",
    "currentTab",
    "hasControl",
    "resetToken",
    "lang",
    "userLogged",
    "globalType"
  ]),

  data: function () {
    return {
      name: "",
      ip: "",
      isNone: false,
      isProg: false,
      selected: 0,
      progressives: [],
      progressive: {},
      megapotSpin: {},
      terminals: [],
      showContent: false,
      showToken: false,
      showReset: false,
      showGroupMachines: false,
      titleModalAgroupTerminal: "",
      countries: countries,
      data: [{}],
      intervalGetDatas: null,
      object: {
        data: [],
        columns: [],
        isMulti: false,
        isGlobal: false,
      },
      showAvailable: false,
      stickyHeaders: true,
      showProgressive: false,
      searchTerminal: "",
      searchTerminalProg: "",
      searchTerminalMultiProg: "",
      htmlcontent: "",
      showLoadingCMD: false,
      showLoadingMoveProgressiveCMD: false,
      cmd: "",
      countDown: 60,
      route: "",
      sendingCMDMessage: "",
      showActionsMulti: false,
      enableShowProgressive: true,
      resetProgOrMulti: {},
      restartCommunication: false,
      selectAll: false,
      selectedMachines: [],
      listMachineLenght: 0,
      isFromSelectAll: false,
      isFromSelectedMachines: false,
      selectedProgressive: "",
      selectedMultiProg: "",
      availableTerminals: [],
      obj: {
        progName: "",
        multiName: "",
        Terminals: [],
      },
      showTerminalOptions: false,
      showLoadingTerminalOptionsCMD: false,
      terminalOptions: terminalOptions,
      selectTerminalOption: 0,
      selectedTerminal: {},
      optionAgroupMachines: 255,
      selectedTab: 0,
      globalTypeEnum: globalType,
      megapotsInfo: {
        Bingo: [],
        Spins: [],
        DataMegapot: [],
      },
      gameType: gameType,
    };
  },
  props: {
    nameTab: String,
  },

  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    closeProgressive() {
      this.showProgressive = false;
      this.progressive = {};
      this.searchTerminal = "";
    },
    processResetProgressive(obj, type, prog) {
      (this.restartCommunication = false), (this.resetProgOrMulti = obj);
      if (type === "prog") {
        this.resetProgOrMulti.isGlobal = true;
        this.resetProgOrMulti.isMulti = false;
        this.resetProgOrMulti.MultiId = 0;
        this.resetProgOrMulti.typeModule = typesModules.prog;
      } else {
        this.resetProgOrMulti.isMulti = true;
        this.resetProgOrMulti.ProgId = prog.ProgId;
        this.resetProgOrMulti.typeModule = typesModules.multi;
      }
      if (this.resetToken) {
        this.showToken = true;
      } else {
        this.afterToken();
      }
    },
    afterToken() {
      if (this.communication) {
        //

        this.route = "/modules/progressive/Token";
        this.$buefy.dialog.confirm({
          title: this.translate("dialog_Title"),
          message:
            this.translate("messageBlockCommunication") +
            " <br/> " +
            this.translate("messageAllMachinesDisconnect"),
          confirmText: this.translate("btnYes"),
          cancelText: this.translate("btnCancel"),
          type: "is-primary",
          hasIcon: true,
          onConfirm: () =>
            this.sendCMD(
              clientCommands.BlockCommunication.name,
              this.translate("waiting") +
              " " +
              this.translate("cmdBlockCommunication"),
              clientCommands.BlockCommunication.time,
              true
            ),
        });
      } else {
        this.resetProgressive();
      }
    },
    sendCMD(cmd, message, time, isRestartCommunication = false) {
      this.cmd = cmd;
      this.countDown = time;
      this.restartCommunication = isRestartCommunication;
      this.sendingCMDMessage = message;
      this.showLoadingCMD = true;
    },
    actionAfterLoadingProgressive() {
      switch (this.route) {
        case "/modules/progressive/Token":
          this.resetProgressive();
          break;
      }
    },
    resetProgressive() {
      this.showReset = true;
    },
    showBonusData(bonus) {
      this.showBonus = true;
      this.updateBonusData(bonus);
    },
    showProgressiveData(progressive) {
      this.showProgressive = true;
      this.updateProgressiveData(progressive);
      this.getDatas();
    },

    updateProgressiveData(progressive) {
      this.progressive = progressive;
      //this.megapotSpin = this.dataModule.GlobalData.Globals.filter(
      // (megapot) => megapot.GameType == gameType.spin
      //);
      console.log("updateMegapotData()");
      let listMegapots = [];      
      listMegapots.push(this.progressive)
      //listMegapots.push(this.megapotSpin[0])
      try {
        this.megapotsInfo.Bingo = [];
        this.megapotsInfo.Spins = [];
        this.megapotsInfo.DataMegapot = [];


        //Dados em comum Bingo = Spin
        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnMinSort"),
          value: progressive.MinSort,
        });
        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnMaxSort"),
          value: progressive.MaxSort,
        });

        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnInitialValue"),
          value: progressive.IniValue,
        });
        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnMaxValue"),
          value: progressive.MaxValue,
        });

        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnName"),
          value: progressive.Name
        });
        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnProgressiveType"),
          value: progressive.ProgType,
        });
        if (progressive.ProgType == "Normal" || progressive.ProgType == "Proportional") {
          this.megapotsInfo.DataMegapot.push({
            field:
              progressive.ProgType == "Normal"
                ? this.translate("columnMinBetToPartipate")
                : this.translate("columnMaxProportionalBet"),
            value:
              progressive.ProgType == "Normal"
                ? progressive.MinBet
                : progressive.MaxBet,
          });
        }
        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnContribution"),
          value: progressive.Contribution,
        });
        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnEnableIniCont"),
          value: progressive.EnableAcInitialValue ? this.translate("btnYes") : this.translate("btnNo"),
        });
        if (progressive.EnableAcInitialValue) {
          this.megapotsInfo.DataMegapot.push({
            field: this.translate("columnIniContribution"),
            value: progressive.ContributionInitial,
          });
          this.megapotsInfo.DataMegapot.push({
            field: this.translate("columnIniValAcumulated"),
            value: progressive.ValueInitial,
          });
        }

        this.megapotsInfo.DataMegapot.push({
          field: this.translate("lblValue"),
          value: progressive.Value
        });
        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnEnableIncSound"),
          value: progressive.EnableIncSound ? this.translate("btnYes") : this.translate("btnNo"),
        });
        if (progressive.EnableIncSound) {
          this.megapotsInfo.DataMegapot.push({
            field: this.translate("columnIncrementSoundValue"),
            value: progressive.IncSoundValue,
          });
        }

        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnTimeBetweenDraws"),
          value: progressive.MinTimeBetweenDraws,
        });
        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnMinGameStartTrg"),
          value: progressive.MinGameStart,
        });
        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnMinOccupationTrg"),
          value: progressive.MinOccupation,
        });
        this.megapotsInfo.DataMegapot.push({
            field: this.translate("columnPercRoom"),
            value: progressive.PercentRoomServer,
          });
        this.megapotsInfo.DataMegapot.push({
          field: this.translate("columnTimeoutClosedSession"),
          value: progressive.TimeoutClosedSession,
        });

        ///
        listMegapots.forEach(megapot => {

          let infoMegapot = []
          //Bingo
          infoMegapot.push({
            field: this.translate("columnPlayJackpotSoundOn"),
            value: megapot.PlayJackpotOnAllMachines,
          });
          infoMegapot.push({
            field: this.translate("columnConfigName"),
            value: megapot.ConfigName,
          });
          infoMegapot.push({
            field: this.translate("columnFracName"),
            value: megapot.FracName,
          });

          infoMegapot.push({
            field: this.translate("columnConsecutivePrizesSrv"),
            value: megapot.NumberOfConsecutivePrizesServer,
          });
          infoMegapot.push({
            field: this.translate("columnMinOccSrv"),
            value: megapot.MinOccupationServer,
          });
          infoMegapot.push({
            field: this.translate("columnConsecutivePrizesMac"),
            value: megapot.ConsecutivePrizesMac,
          });
          infoMegapot.push({
            field: this.translate("columnMinGameStartMac"),
            value: megapot.MinGameStartMac,
          });
          infoMegapot.push({
            field: this.translate("columnTimeEligibleGame"),
            value: megapot.TimeEligibleGame,
          });
          infoMegapot.push({
            field: this.translate("columnTimeEligibleExtra"),
            value: megapot.TimeEligibleExtra,
          });
          infoMegapot.push({
            field: this.translate("columnTimeEligibleBonus"),
            value: megapot.TimeEligibleBonus,
          });
          infoMegapot.push({
            field: this.translate("columnTimeoutConnection"),
            value: megapot.TimeoutConnection,
          });
          infoMegapot.push({
            field: this.translate("columnTimeoutCancelPrize"),
            value: megapot.TimeoutCancelPrize,
          });
          infoMegapot.push({
            field: this.translate("columnDecPercToWinAgainSrv"),
            value: megapot.DecreasePercToWinAgainServer,
          });
          infoMegapot.push({
            field: this.translate("columnActiveMachSrv"),
            value: megapot.ActiveMachinesServer,
          });
          infoMegapot.push({
            field: this.translate("columnCoinInSrv"),
            value: megapot.CoinInServer,
          });          
          infoMegapot.push({
            field: this.translate("columnNetMac"),
            value: megapot.NetMac,
          });
          infoMegapot.push({
            field: this.translate("columnTimePlayingMac"),
            value: megapot.TimePlayingMac,
          });
          infoMegapot.push({
            field: this.translate("columnCoinInMac"),
            value: megapot.CoinInMac,
          });
          infoMegapot.push({
            field: this.translate("columnPercTerm"),
            value: megapot.PercTerm,
          });

          this.isNone = megapot.IsNone;
          this.name = this.isNone
            ? "-- NONE --"
            : this.translate("globalProgressive_Title") +
            " - " +
            megapot.Name;

          //TODO retirado na atualização Megapot Spins
          /*megapot.dataEngine = [];
          megapot.Engines.forEach((engine) => {
            const dataEngine = [
              {
                field: engine.Name,
              },
            ];
            Array.prototype.push.apply(megapot.dataEngine, dataEngine);
          });*/


          this.getDetailFrac(megapot.FracData, infoMegapot);
          this.megapotsInfo.Bingo = infoMegapot

          infoMegapot = []

          //Spin
          infoMegapot.push({
            field: this.translate("columnPlayJackpotSoundOn"),
            value: megapot.PlayJackpotOnAllMachinesSpin,
          });          
          infoMegapot.push({
            field: this.translate("columnConfigName"),
            value: megapot.ConfigNameSpin,
          });
          infoMegapot.push({
            field: this.translate("columnFracName"),
            value: megapot.FracNameSpin,
          });

          infoMegapot.push({
            field: this.translate("columnConsecutivePrizesSrv"),
            value: megapot.NumberOfConsecutivePrizesServerSpin,
          });
          infoMegapot.push({
            field: this.translate("columnMinOccSrv"),
            value: megapot.MinOccupationServerSpin,
          });
          infoMegapot.push({
            field: this.translate("columnConsecutivePrizesMac"),
            value: megapot.ConsecutivePrizesMacSpin,
          });
          infoMegapot.push({
            field: this.translate("columnMinGameStartMac"),
            value: megapot.MinGameStartMacSpin,
          });
          infoMegapot.push({
            field: this.translate("columnTimeEligibleGame"),
            value: megapot.TimeEligibleGameSpin,
          });
          infoMegapot.push({
            field: this.translate("columnTimeEligibleExtra"),
            value: megapot.TimeEligibleExtraSpin,
          });
          infoMegapot.push({
            field: this.translate("columnTimeEligibleBonus"),
            value: megapot.TimeEligibleBonusSpin,
          });
          infoMegapot.push({
            field: this.translate("columnTimeoutConnection"),
            value: megapot.TimeoutConnectionSpin,
          });
          infoMegapot.push({
            field: this.translate("columnTimeoutCancelPrize"),
            value: megapot.TimeoutCancelPrizeSpin,
          });          
          infoMegapot.push({
            field: this.translate("columnDecPercToWinAgainSrv"),
            value: megapot.DecreasePercToWinAgainServerSpin,
          });
          infoMegapot.push({
            field: this.translate("columnActiveMachSrv"),
            value: megapot.ActiveMachinesServerSpin,
          });
          infoMegapot.push({
            field: this.translate("columnCoinInSrv"),
            value: megapot.CoinInServerSpin,
          });          
          infoMegapot.push({
            field: this.translate("columnNetMac"),
            value: megapot.NetMacSpin,
          });
          infoMegapot.push({
            field: this.translate("columnTimePlayingMac"),
            value: megapot.TimePlayingMacSpin,
          });
          infoMegapot.push({
            field: this.translate("columnCoinInMac"),
            value: megapot.CoinInMacSpin,
          });
          infoMegapot.push({
            field: this.translate("columnPercTerm"),
            value: megapot.PercTermSpin,
          });

          this.isNone = megapot.IsNone;
          this.name = this.isNone
            ? "-- NONE --"
            : this.translate("globalProgressive_Title") +
            " - " +
            megapot.Name;

          //TODO retirado na atualização Megapot Spins
          /*megapot.dataEngine = [];
          megapot.Engines.forEach((engine) => {
            const dataEngine = [
              {
                field: engine.Name,
              },
            ];
            Array.prototype.push.apply(megapot.dataEngine, dataEngine);
          });*/

          this.getDetailFrac(megapot.FracDataSpin, infoMegapot);
          this.megapotsInfo.Spins = infoMegapot


        });

      } catch (e) {
        console.log(e);
      }
    },
    multiData() {
      //
    },

    getDetailFrac(listFrac, infoMegapot) {
      try {
        //Columns        
        let denoms = [];
        listFrac.forEach((frac) => {
          if (!denoms.includes(frac.Denom)) {
            denoms.push(frac.Denom);
          }
        });
        let columns = [];
        columns.push({
          field: this.translate("columnTotalBet"),
          label: this.translate("columnTotalBet"),
        }, {
          field: this.translate("columnPercentage"),
          label: this.translate("columnPercentage"),
        });
        let lines = [];

        listFrac.forEach((frac) => {
          let found = undefined;
          lines.forEach((line) => {
            if (line.Bet == frac.Bet) {
              found = line;
              return;
            }
          });
          if (found == undefined) {
            let newLine = {
              Bet: 0,
              Denom: [],
              Perc: [],
            };
            newLine.Bet = frac.Bet;
            newLine.Denom.push(frac.Denom);
            newLine.Perc.push(frac.Perc);
            lines.push(newLine);
          } else {
            found.Denom.push(frac.Denom);
            found.Perc.push(frac.Perc);
          }
        });
        infoMegapot.columnsFrac = columns;
        infoMegapot.linesFrac = lines;
      } catch (e) {
        console.log("Error in getDetailFrac() " + e);
      }
    },
    getDatas() {
      this.intervalGetDatas = setInterval(() => {
        if (!this.showProgressive) {
          console.log("/closed getDatas()");
          clearInterval(this.intervalGetDatas);
        }

        this.dataModule.progressivesGlobals.forEach((progressive) => {
          if (this.showProgressive) {
            console.log("/mountedDataGlobalProgressive()");
            if (progressive.ProgId == this.progressive.ProgId) {
              this.updateProgressiveData(progressive);
            }
          }
        });
      }, 1000);
    },
    returnIcon(icon) {
      const ret = icon;
      return ret;
    },
  },
  mounted() {
    this.name = "";
    this.ip = "";
  },

  watch: {
    searchTerminal(value) {
      console.log(value);
      //
    },
    selected(value) {
      if (value == 0) {
        this.$store.commit("setSelectedTab", "GlobalProgressive");
      } else if (value == 1) {
        this.$store.commit("setSelectedTab", "Terminals");
      }
    },
  },
  async created() {
    //if (this.currentTab == "GlobalProgressive" || this.currentTab == "Terminals")
    //this.getDatas();
  },
  beforeDestroy() {
    console.log("/closed getDatas() Global");
    clearInterval(this.intervalGetDatas);
  },
};
</script>

<style scoped src="@/css/styleModules.css"></style>
