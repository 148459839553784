import { render, staticRenderFns } from "./ResetSetting.vue?vue&type=template&id=3f5ef50e&scoped=true"
import script from "./ResetSetting.vue?vue&type=script&lang=js"
export * from "./ResetSetting.vue?vue&type=script&lang=js"
import style0 from "@/css/componentDropdown.css?vue&type=style&index=0&id=3f5ef50e&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f5ef50e",
  null
  
)

export default component.exports