import { render, staticRenderFns } from "./IniFile.vue?vue&type=template&id=14131d7d&scoped=true"
import script from "./IniFile.vue?vue&type=script&lang=js"
export * from "./IniFile.vue?vue&type=script&lang=js"
import style0 from "@/css/styleModules.css?vue&type=style&index=0&id=14131d7d&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14131d7d",
  null
  
)

export default component.exports